.app__info {
  position: fixed;
  width: 100%;
  height: auto;
  max-height: 97svh;
  min-height: 97svh;
  max-width: 940px;
  top: 1.8rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(14, 14, 14, 0.81);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 2000px) {
    max-width: 50.5%;
    max-height: 97.8svh;
  }

  @media screen and (max-width: 1000px) {
    max-width: 97.75%;
    max-height: 97.7svh;
  }

  border-radius: 0.75rem;
  box-shadow: 0.5px 0.5px 15px rgba(0, 0, 0, 0.5);

  .app__info-videoDetails {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.75rem;
    z-index: 3;
  
    overflow-y: auto;
    overflow-x: hidden;
  
    .app__info-video {
      position: relative;
      width: 100%;  
      height: 55svh;
    
      .app__info-content {
        position: relative;
        width: 100%;
        height: 100%;    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        video {
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: relative;      
          z-index: 0;
    
          border-radius: 0.75rem 0.75rem 0.3rem 0.3rem;
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-shadow: 0.25rem 0.25rem 6px rgba(0, 0, 0, 0.5);  
        }
    
        .app__info-logos {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 2.2rem;
          padding-bottom: 3rem;
          z-index: 1;
    
          @media screen and (max-width: 1000px) {
            padding-left: 2.2rem;
            padding-bottom: 3.0rem;
          }
    
          @media screen and (max-width: 800px) {
            padding-left: 1.8rem;
            padding-bottom: 2.25rem;
          }
    
          @media screen and (max-width: 600px) {
            padding-left: 1.55rem;
            padding-bottom: 1.55rem;
          }
    
          @media screen and (max-width: 450px) {
            padding-left: 0.8rem;
            padding-bottom: 1.2rem;
          }
    
          .info-logo-image {
            max-width: 100%;
            width: 43%;
            height: auto;
            position: relative;
            z-index: 1;
    
            @media screen and (max-width: 1000px) {
              width: 43%;
            }
    
            @media screen and (max-width: 450px) {
              width: 71%;
            }
    
            @media screen and (max-width: 400px) {
              bottom: 0;
              left: 0;
            }
    
            @media screen and (max-height: 450px) {
              width: 35%;
              padding-left: 0.6rem
            }
    
            @media screen and (max-height: 410px) {
              display: none;
            }  
          }
    
          .notify-me-container {
            display: flex;
            flex-direction: row; // Arrange children in a row
            align-items: center; // Center items vertically in the container        
            justify-content: center;
            padding-left: 1.05rem;
            padding-top: 0.1em;
    
            @media screen and (max-width: 450px) {
              padding-left: 0.25rem;          
            }
    
            @media screen and (max-width: 400px) {
             display: none;    
            }
    
            @media screen and (max-height: 160px) {
              display: none;
            }  
    
            .notify-me-box,
            .submitted-message {
              margin-right: 1rem; // Add spacing between Notify Me box and Submitted message
            }
    
            // Add styles for Notify Me box
            .notify-me-box {
              display: flex;
              margin-top: 1rem;
              font-family: var(--font-base);
    
              input {
                padding: 0.5rem;
                margin-right: 1rem;
                color: white;
                text-shadow: 0.2px 0.5px 4px rgba(0, 0, 0, 0.35);
                opacity: 1;
    
                font-size: 1rem;
                font-weight: bold;
                background-color: rgba(0, 0, 0, 0.15);
                border: 2px solid white; // Replace with your desired border color
                border-color: white;
    
                outline: none;
    
                font-family: var(--font-base);
              }
    
              button {
                padding: 0.5rem 0.75rem;
                background-color: rgba(0, 0, 0, 0.45);
                color: var(--white-color);
                font-size: 0.9rem;
                font-weight: bold;            
                text-shadow: 0.2px 0.5px 4px rgba(0, 0, 0, 0.35);
                outline: none; // Add this line to remove the outline            
                border: 1.5px solid white; // Replace with your desired border color
                cursor: not-allowed; // Default to not-allowed
                transition: all 0.3s ease-in-out; // Smooth transition for background color
                font-family: var(--font-base);
    
                &[data-valid="true"] {
                  cursor: pointer;
                }
    
                &:hover {
                  &[data-valid="true"] {
                    background-color: rgba(255, 0, 0, 0.75);
                  }
                }
              }
            }
    
            // Add styles for Submitted message
            .submitted-message {
              margin-top: 1rem;
              color: var(--white-color);
              text-shadow: 0.2px 0.5px 4px rgba(0, 0, 0, 0.75);
              font-weight: bold;
            }
          }
        }
    
        .exit-button {
          position: fixed;
          top: 10px;
          right: 10px;
          cursor: pointer;
          background: none; /* Remove the background */
          border: none; /* Remove the border */
          z-index: 99; /* Ensure the exit button appears above other elements */
          filter: drop-shadow(0px 0.5px 1.5px rgba(0, 0, 0, 0.5));
    
          img {
            width: 36px;
            height: auto;
          }
        }
    
        .grey-gradient-image {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: auto;
        }
      }
    }
    
  }
}


.info-text-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  
  padding-left: 2.5rem;
  max-width: 600px;

  @media screen and (min-width: 2000px) {
    max-width: 850px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 480px;
  }

  @media screen and (max-width: 800px) {
    max-width: 350px;
  }
  
  @media screen and (max-width: 600px) {
    padding-left: 2rem;
  }

  @media screen and (max-width: 500px) {
    max-width: 300px;
  }

  @media screen and (max-width: 450px) {
    padding-left: 1.25rem;
  }
}

.app__info-platform-logos {  
  flex-direction: row;
  margin-top: 0.8rem;

  .platform-logo {
    width: 35px;
    margin-right: 0.8rem;
    transform: scale(1);
    transition: all 0.3 ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.info-subtitle-text {
  font-size: 1.2rem;
  font-weight: 800;
  color: var(--white-color);
  text-transform: capitalize;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.45);
  margin-top: 0.6rem;

  @media screen and (min-width: 2000px) {
    font-size: 1.7rem;
  }

  @media screen and (max-width: 1000px) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.35rem;
  }
}

.longDescription-text {
  font-size: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 3.5rem;
  position: relative;
  color: var(--white-color);
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  z-index: 1;

  @media screen and (min-width: 2000px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 1000px) {
    font-size: 1.0rem;
  }

  @media screen and (max-width: 450px) {
    margin-top: 0.31rem;
  }
}
