@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #9e9e9e;
  --secondary-color: #303030;
  --yellow-color: rgb(238, 255, 0);
  --offWhite-color: rgb(255, 254 252);
  --peach-color: #EEA59D;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --darkGray-color: rgb(20,20,20);
  --darkGrey-color: rgb(20,20,20);
  --offBlack-color: rgba(10,10,10, 0.98);
  --brown-color: #46364a;
  --white-color: #ffffff;
  --black-color: rgb(0, 0, 0);
  --offWhite-color: #dddddd;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}