.app__privacy {
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--darkGrey-color);
  padding: 0.2rem 0rem 0rem;
  position: relative;

  .privacy-container {
    // Add your styling for the privacy container here, for example:
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    min-height: 100vh;
    padding-top: 3.8rem;
    font-family: 'Arial', sans-serif;
    background-color: var(--darkGrey-color);

    h1 {
      color: var(--white-color);
    }

    h2 {
      color: var(--white-color);
      padding-top: 0.35rem;
    }

    p {
      color: var(--white-color);
      padding-top: 0.15rem;
    }

    ul {
      list-style-type: disc;
      margin-left: 20px;
      color: var(--white-color);
    }
  }
}
  

.app__privacy {
  flex: 1;
  width: 100%;
  min-height: 100vh;
  background-color: var(--darkGrey-color);
  padding: 17px 20px; /* Increased padding for better spacing */
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacy-container {
  max-width: 800px;
  width: 100%;
  padding: 40px; /* Increased padding for better spacing */
  background-color: #ffffff; /* White background for the container */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
}

.privacy-container h1 {
  color: #333333; /* Darker text color */
  margin-bottom: 20px; /* Added margin for better spacing */
}

.privacy-container h2 {
  color: #333333; /* Darker text color */
  margin-top: 20px; /* Added margin for better spacing */
}

.privacy-container p {
  color: #666666; /* Slightly darker text color */
  margin-bottom: 10px; /* Added margin for better spacing */
}

.privacy-container ul {
  color: #666666; /* Slightly darker text color */
  margin-bottom: 10px; /* Added margin for better spacing */
}

.privacy-container ul li {
  list-style-type: disc;
  margin-left: 20px;
}

.privacy-container a {
  color: #007bff; /* Link color */
}
