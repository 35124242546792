@mixin fadeInDelay($index) {    
    animation-delay: $index * 0.1s;
    animation: fade 1.1s ease-in forwards;
  }
  
  @mixin bgFadeInDelay($delay) {    
    animation-delay: $delay;
    animation: bgFadeIn 0.6s ease-in forwards;
  }
  
  @keyframes fade {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes bgFadeIn {
    from {
      background-color: rgb(62, 62, 62);
    }
    to {
      background-color: rgb(22, 22, 22);
    }
  }
  
  @keyframes bgFadeOut {
    from {
      background-color: rgb(22, 22, 22, 1);
    }
    to {
      background-color: rgb(62, 62, 62, 1);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}
  
body.menu-open {
    overflow: hidden;
  }

  .app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.05rem 2rem;
    background: rgba(40, 40, 40, 0.99);
    position: fixed;
    z-index: 900;
    overflow: hidden; /* Ensure the overlay covers the entire navbar */

    backdrop-filter: blur(24px); // Initial blur value
    &.app__navbar-menu-open {
      backdrop-filter: none; // Add the styles to remove the blur
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) -5%, rgba(0, 0, 0, 0.1) 81%, rgba(0, 0, 0, 0) 99%);
      z-index: -1; /* Place the overlay behind the navbar content */
    }
  }
  
  .app__navbar-logo {
    display: flex;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-left: 12px;
    margin-right: 12px;
    transition: all 0.3s ease-in-out;
    z-index: 4;    
    filter: brightness(1.5);
    filter: drop-shadow(0.4px 0.6px 0px rgba(0, 0, 0, 0.65));

    @media screen and (max-width: 799px) {
      margin-left: -15px;
      margin-top: 3px;
    }
  }
  
  .app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    li {
      margin: 0 0.95rem;
      cursor: pointer;
      div {
        width: 0px;
        height: 0px;
        background: transparent;
        border-radius: 50%;
        margin-bottom: 5px;
      }
      a {
        color: rgb(235, 235, 235);
        text-decoration: none;
        flex-direction: column;
        text-transform: capitalize;
        font-weight: 500;
        transition: all 0.3s ease-in-out;
        text-shadow: 0.4px 0.6px 0px rgba(0, 0, 0, 0.65);
        font-size: 0.98rem;
        z-index: 4;
        
        &:hover {
          color: rgba(205, 205, 205, 1);
        }
        &.active {
          cursor: default;
          color: var(--white-color);
          font-weight: bold;
          text-shadow: 0.5px 0.7px 0px rgba(0, 0, 0, 0.65);
          filter: drop-shadow(0px 0px 0.1px rgba(255, 255, 255, 0.5));
        }
      }
      &:hover {
        div {
          background: var(--white-color);
        }
      }
    }
    @media screen and (max-width: 799px) {
        // hide all items except the logo
        justify-content: flex-start;
        li:not(:first-child) {
        display: none;
      }
    }
  }
  
  .app__navbar-menu {
    width: 36px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    svg {
      width: 50%;
      height: 50%;
    }
    .menu-icon {
      filter: brightness(0.75);
      z-index: 4;
      color: var(--offWhite-color);
      filter: brightness(1.4);
      filter: drop-shadow(0.3px 0.55px 0px rgba(0, 0, 0, 0.65));
      transition: all 0.3s ease-in-out;
      margin-top: 4px;
      margin-right: -2px;
      cursor: pointer;

      &:hover {
        color: rgba(195, 195, 195, 1);
        filter: brightness(1.2);        
        filter: drop-shadow(0.3px 0.55px 0px rgba(0, 0, 0, 0.65));
        cursor: pointer;
      }
    }
    .close-icon {
      padding-left: 1.25rem;
      padding-top: 0.4rem;
      filter: brightness(4.5);
      transition: all 0.25s ease-in-out;
      cursor: pointer;
      &:hover {
        filter: brightness(2.75);
        cursor: pointer;
      }
    }
    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
      padding: 1rem;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      background-color: rgb(62, 62, 62);
      &.entered {
        @include bgFadeInDelay(0);
      }
      &.exited {
        animation: bgFadeOut 0.5s ease-in-out forwards;
      }
      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        li.entered {
          margin: 1rem;
          a {
            color: var(--offWhite-color);
            opacity: 0;
            text-decoration: none;
            font-size: 1.6rem;
            text-transform: capitalize;
            font-weight: 500;            
            transition: all 0.3 ease-in-out;
            text-shadow: 0 0 2.5px rgba(0, 0, 0, 0.4);
            &:hover {
              transition: all 0.2s ease-in-out;
              color: rgb(145, 145, 145);
            }
            &:not(:hover) {
              transition: all 0.3s ease-in-out;
              color: var(--offWhite-color);
            }
            &.active {
              color: var(--white-color);
              font-weight: bold;
              text-shadow: 0 0 0.5px rgba(255, 255, 255, 0.025);
            }
            &:nth-child(1) {
                @include fadeInDelay(1);
            }
            &:nth-child(2) {
                @include fadeInDelay(2);
            }
            &:nth-child(3) {
                @include fadeInDelay(3);
            }
            &:nth-child(4) {
                @include fadeInDelay(4);
            }
            &:nth-child(5) {
                @include fadeInDelay(5);
            }       
          }
        }
        li.exited {
            margin: 1rem;
            a {
                color: var(--offWhite-color);
                text-shadow: 0 0 5px rgba(255, 255, 255, 0);
                opacity: 0;
                text-decoration: none;
                font-size: 1.6rem;
                text-transform: capitalize;
                font-weight: 500;                  
                animation: fadeOut 0.05s ease-in-out forwards; 
            }
          }
      }
      @media screen and (min-width: 799px) {
        display: none;
      }
    }
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  