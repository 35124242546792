.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;

  .app__work-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 0.25rem;

    .header-text {
      font-size: 1.4rem;
      color: var(--white-color);
      text-transform: capitalize;
      margin-right: 1rem;
    }
  }

  .app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .app__work-item {
      width: 235px;
      flex-direction: column;

      margin: 1.15rem;
      margin-top: 0.6rem;
      border-radius: 0.45rem;
      color: #000;

      cursor: pointer;
      transition: all 0.3s ease;

      @media screen and (min-width: 2000px) {
        width: 350px;
        margin: 1.35rem;
        margin-top: 1rem;
      }

      @media screen and (max-width: 300px) {
        width: 100%;
        margin: 1rem;
      }

      &.hover-active {        
        border-radius: 0.45rem;
        // Styles for hover when modal is active (makes the element [bounce] when clicked)
        transform: scale(1.35);
      }

      .app__work-vid {
        width: 100%;
        height: 235px;        
        border-radius: 0.45rem;
        position: relative;

        @media screen and (min-width: 2000px) {
          width: 350px;
          height: auto;
        }

        @media screen and (max-width: 300px) {
          width: 100%;
        }

        .info-icon {
          position: absolute;
          bottom: 2px;
          right: 7px;
          z-index: 2;

          @media screen and (min-width: 2000px) {
            bottom: 5px;
            right: 12px;
          }
          
          img {
            width: 23px; // Adjust the size of the info icon
            height: auto;

            @media screen and (min-width: 2000px) {
              width: 35px;
            }
          }
        }
        
      
        video {
          width: 100%;
          height: 100%;
          border-radius: 0.45rem;
          box-shadow: 0.25rem 0.25rem 6px rgba(0, 0, 0, 0.5);
          //border: 1px solid rgba(255, 255, 255, 0.05);
          object-fit: cover;
          z-index: 1;
          transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        }
      
        &:hover {
          z-index: 2;          
          border-radius: 0.45rem;
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
          transform: scale(1.35);
          transition: all 0.3s ease-in-out;
        }

        &.hover-active {
          // Styles for hover when modal is active          
          border-radius: 0.45rem;
          transform: scale(1.35);
        }
      
        &:not(:hover):not(.active) {
          transition: box-shadow 0.3s ease-in-out, z-index ease-in-out 0.3s, transform 0.3s ease-in-out; // Add delay to transform
          box-shadow: 0 0 25px rgba(0, 0, 0, 0);
          border-radius: 0.45rem;
          z-index: 1;
          transform: scale(1); // You can set the default scale here
      
          &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-radius: 0.45rem;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      &.hover-active {
        // Styles for hover when modal is active        
        border-radius: 0.45rem;
        transform: scale(1.35);
      }
    }
  }
  
  .app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(40, 40, 40, 0);
    border-radius: 0.45rem;
    transition: background-color 0.3s ease-in-out;
    z-index: 3;

    &:hover {
      background-color: rgba(40, 40, 40, 0.1);
    }

    &.hover-active {
      background-color: rgba(40, 40, 40, 0.1);
    }

    &:not(:hover) {
      transition: background-color 0.3s ease-in-out;
      background-color: rgba(40, 40, 40, 0);
    }
  }

  .app__work-content {
    width: 100%;
    position: relative;
    flex-direction: column;

    h4 {
      margin-top: 1rem;
      line-height: 1.5;

      @media screen and (min-width: 2000px) {
        margin-top: 3rem;
      }
    }

    .app__work-titles {
      padding: 0.5rem 1rem;
      top: -45px;
    }
  }
}

.app__work-selected {
  cursor: pointer;
  display: flex;
  align-items: center; /* Align items vertically */
  padding: 0.6rem 0.75rem;
  padding-bottom: 0.25rem;
  border: 1px solid #ccc;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: 800;
}

.dropdown-icon {
  width: auto;
  height: 100%; /* Set height to 100% to match the text height */
  margin-left: 1rem; /* Add some margin for spacing */
  img {
    width: 12px; /* Adjust the width of the triangle icon */
    height: 100%;
  }
}

.app__work-dropdown {
  position: relative;
}

.app__work-options {
  position: absolute;
  top: calc(100% + 0.05px); /* Adjust the spacing between the dropdown and options */
  width: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.93);
  backdrop-filter: blur(3px);
  border: 0.25px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  z-index: 1000;

  div {
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 950;
  backdrop-filter: blur(1.2px);
  background: rgba(49, 49, 49, 0.8);
  cursor: pointer;
}
