.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 2rem 2rem;
  background-color: var(--darkGray-color);

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
  padding-bottom: 1rem;
  padding-top: 2rem;

  .footer-links {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0.75rem;

    padding-top: 2rem;;

    .footer-link {
      color: var(--lightGray-color);
      margin: 0 1rem;
      text-decoration: none;
      font-weight: 500;
      transition: color 0.3s ease;

      &:hover {
        color: var(--white-color);
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.15);
      }
    }
  }

  .copyright {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      color: var(--white-color);
    }
  }
}