#home {
  position: relative;
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  background-color: var(--darkGray-color);

  // Disable the default padding styles
  .app__wrapper {
      padding: 0;

      .copyright {
          display: none;
      }
  }
}

body.active-modal {
    overflow-y: hidden;
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  
  padding: 0rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
      padding-top: 0rem;
  }

  @media screen and (max-width: 450px) {
      padding: 0rem 1rem 0rem;
  }
}

.app__header-videoDetails {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5rem;
    padding-bottom: 5rem;

    @media screen and (max-width: 450px) {
        padding-bottom: 3rem;
        padding-left: 3.6rem;
    }

    @media screen and (max-height: 450px) {
        padding-bottom: 3rem;
        padding-left: 3rem;
    }  
}

.app__header-white-screen {
    position: absolute;
    width: 100%;
    height: 91.25%;
    background-color: var(--offWhite-color);
    z-index: -3;
    opacity: 0; /* Add initial opacity */
    transition: opacity 0.33s ease; /* Add transition */
}

.logo-image {
    max-width: 100%;
    width: 30%;
    height: auto; // Adjusted to maintain aspect ratio
    position: relative;
    z-index: 1;
    margin-left: -0.25rem;

    @media screen and (min-width: 4200px) {
        width: 20%;
    }

    @media screen and (max-width: 1600px) {
        width: 33%;
    }

    @media screen and (max-width: 1000px) {
        width: 43%;
    }

    @media screen and (max-width: 450px) {
        width: 61%;
    }

    @media screen and (max-height: 450px) {
        width: 40%;
    }
    
    @media screen and (max-height: 190px) {
        display: none;
    }

    @media screen and (max-width: 230px) {
        width: 99%;
        margin-left: -1.75rem;
    }
}

.text-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    max-width: 600px;
    overflow: hidden;

    @media screen and (min-width: 2000px) {
        max-width: 850px;
    }

    @media screen and (max-width: 1200px) {
        max-width: 480px;
    }

    @media screen and (max-width: 800px) {
        max-width: 350px;
    }

    @media screen and (max-width: 450px) {
        max-width: 280px;
    }

    @media screen and (max-width: 230px) {
        display: none;
    }
}

.subtitle-text {
    font-size: 2.075rem;
    font-weight: 800;
    color: var(--white-color);
    text-transform: capitalize;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.45);
    white-space: normal;
    margin-top: 0.5rem;

    @media screen and (min-width: 2000px) {
        font-size: 2.6rem;
        margin-top: 0.68rem;
    }

    @media screen and (max-width: 1200px) {
        font-size: 1.8rem;
        margin-top: 0.41rem;
    }

    @media screen and (max-width: 800px) {
        font-size: 1.5rem;
        margin-top: 0.32rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 1.35rem;
        margin-top: 0.32rem;
    }

    @media screen and (max-height: 450px) {
        display: none;
    } 
}

.description-text {
    font-size: 1.45rem;
    margin-top: 0.65rem;
    position: relative;
    color: var(--white-color);
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
    z-index: 1;
    white-space: normal;

    @media screen and (min-width: 2000px) {
        font-size: 2rem;
        margin-top: 0.75rem;
    }

    @media screen and (max-width: 1200px) {
        font-size: 1.1rem;
        margin-top: 0.42rem;
    }

    @media screen and (max-width: 800px) {
        margin-top: 0.4rem;
        font-size: 1rem;
    }

    @media screen and (max-width: 450px) {
        margin-top: 0.31rem;
        font-size: 0.9rem;
    }

    @media screen and (max-height: 450px) {
        display: none;
    } 
}

.more-info-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(130, 130, 130, 0.8);
    text-decoration: none; // Remove underline
    color: var(--white-color);
    border: none;
    border-radius: 4px; // adjust as needed
    padding: 10.5px; // adjust as needed
    padding-left: 21px;
    padding-right: 21px;
    margin-top: 27px; // adjust as needed
    cursor: pointer;
    font-size: 1.65rem; // adjust as needed
    font-weight: 600;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: background-color 0.1s ease; // optional transition effect

    backdrop-filter: blur(3px);

    @media screen and (max-height: 275px) {
        display: none;
    } 

    z-index: 1;

    // Set a fixed width for the button
    width: fit-content; // Adjust as needed
  
    @media screen and (min-width: 2000px) {
        padding: 10.5px; // adjust as needed
        padding-left: 21px;
        padding-right: 21px;
        margin-top: 28px; // adjust as needed
        font-size: 1.85rem; // adjust as needed
        font-weight: 600;
    }
    
    @media screen and (max-width: 1200px) {
        padding: 10.5px; // adjust as needed
        padding-left: 21px;
        padding-right: 21px;
        margin-top: 28px; // adjust as needed
        font-size: 1.35rem; // adjust as needed
        font-weight: 600;
    }

    @media screen and (max-width: 800px) {
        padding: 10.5px; // adjust as needed
        padding-left: 21px;
        padding-right: 21px;
        margin-top: 19px; // adjust as needed
        font-size: 1.35rem; // adjust as needed
        font-weight: 600;
    }

    @media screen and (max-width: 450px) {
        padding: 10.5px; // adjust as needed
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 12px; // adjust as needed
        font-size: 1.05rem; // adjust as needed
        font-weight: 600;
    }

    &:hover {
      background-color: rgba(5, 5, 5, 0.25);
      backdrop-filter: blur(1px);
    }
  
    .button-logo {
        max-width: 30px; // adjust as needed
        margin-right: 13px; // adjust as needed

        @media screen and (max-width: 800px) {
            max-width: 25px; // adjust as needed
            margin-right: 13px; // adjust as needed
        }

        @media screen and (max-width: 450px) {
            max-width: 20px; // adjust as needed
            margin-right: 9px; // adjust as needed
        }
    }
  }

.app__header-video-bg {
  position: relative;
  padding: 0rem 0rem 0rem;
  font-family: var(--font-base);
  width: 100vw;
  height: 91.25svh; //91.25svh
  overflow: hidden;

  .app__header-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--offWhite-color);
      //border-radius: 0.45rem;

      .grey-gradient-image {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 0; // Set a lower z-index to ensure it's behind the text
      }

      video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 0.25rem 0.25rem 6px rgba(0, 0, 0, 0.5);
          //border: 1px solid rgba(255, 255, 255, 0.1); 

          //border-radius: 0.45rem;
      }

      .main-text,
      .subtitle-text {
          position: relative;
          z-index: 1;
      }
  }
}
