@keyframes press-background-pan {
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
}

.app__press {
    flex: 1;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    flex-direction: column;
    background-color: var(--darkGrey-color);
    padding: 0rem 0rem 0rem;
    position: relative;

    .app__press-background {
        position: fixed;
    }
    
    .app__press-header {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      padding-top: 2.25rem;
      margin-bottom: -1.5rem;

      @media screen and (min-width: 2000px) {
            padding-top: 3rem;
            padding-bottom: 1.5rem;        
            margin-bottom: -2rem;
        }

        @media screen and (max-width: 800px) {
            padding-top: 3.35rem;
            margin-bottom: -0.755rem;
        }

        .app__press-video-player {
            width: 100%;
            max-width: 850px;
            overflow: hidden;
    
            video {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
    }
  
    .app__press-content-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;        
        padding-top: 0.4rem;

        .app__press-content-1 {
            width: 100%;
            max-width: 850px;
            padding: 0.5rem; /* Adjusted padding for a smaller gap */        
            padding-top: 0.1rem;
            padding-left: 0.8rem;
            padding-bottom: 0rem;
            margin-top: -0.4rem;   
            border-radius: 0.75rem 0.75rem 0.25rem 0.25rem;
            background-color: var(--offBlack-color);
            box-shadow: 0.25rem 0.25rem 6px rgba(0, 0, 0, 0.5);
            border: 1px solid rgba(255, 255, 255, 0.1);
            transition: all 0.3s ease-in-out;
            z-index: 1;
              
            &:hover {
                transform: scale(1.025);
                z-index: 2;

                @media screen and (min-width: 2000px) {
                transform: scale(1.05);
                }
            }

            .app__press-section {
                margin-bottom: 0.75rem;
        
                h1 {
                    font-size: 2.1rem;
                    margin-bottom: -0.85rem;
                    color: var(--white-color);
                    text-transform: uppercase;
                  }
                  
                  h1 span {
                    margin-left: -0.15rem;
                    font-size: 1.5rem;
                  }
                  
                  @media screen and (min-width: 2000px) {
                    h1 {
                        font-size: 2.6rem;
                        margin-bottom: -1.25rem;
                    }
                    h1 span {
                        margin-left: -0.2rem;
                        font-size: 1.7rem;
                      }
                  }
                  
                  @media screen and (max-width: 450px) {
                    h1 {
                      width: 60%;
                      font-size: 1.18rem;
                      margin-bottom: -0.5rem;
                    }
                    h1 span {
                        margin-left: 0rem;
                        font-size: 0.92rem;
                      }
                }
    
                h4 {
                    font-size: 3rem;
                    margin-bottom: 0.35rem;
                    text-transform: uppercase;

                    animation: press-background-pan 2s linear infinite;
                    background: linear-gradient(
                        to right,
                        rgb(84, 236, 229),
                        rgb(200, 230, 65),
                        rgb(63, 228, 85),
                        rgb(84, 236, 229)
                        );
                    background-size: 200%;
                    -webkit-background-clip: text;
                    background-clip: text; /* Standard property for compatibility */
                    -webkit-text-fill-color: transparent;
    
                    @media screen and (min-width: 2000px) {
                        font-size: 3.6rem;
                    }
    
                    @media screen and (max-width: 450px) { 
                        font-size: 1.75rem;
                        margin-bottom: 0.25rem;
                    }
                }
            }

            .app__press-games-header {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 1rem;
                margin-top: 0.25rem;
            
                .header-text {
                  font-size: 1.4rem;
                  color: var(--white-color);
                  text-transform: capitalize;
                  margin-right: 1rem;
                }
            }

            .app__press-portfolio {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                .app__press-item {
                    width: 235px;
                    flex-direction: column;

                    margin: 1.15rem;
                    margin-top: 0.6rem;
                    border-radius: 0.45rem;
                    color: #000;

                    cursor: pointer;
                    transition: all 0.3s ease;

                    @media screen and (min-width: 2000px) {
                        width: 350px;
                        margin: 1.35rem;
                        margin-top: 1rem;
                    }

                    @media screen and (max-width: 300px) {
                        width: 100%;
                        margin: 1rem;
                    }

                    .app__press-vid {
                        width: 100%;
                        height: 235px;        
                        border-radius: 0.45rem;
                        position: relative;

                        @media screen and (min-width: 2000px) {
                            width: 350px;
                            height: auto;
                        }

                        @media screen and (max-width: 300px) {
                            width: 100%;
                        }

                        .download-icon {
                            position: absolute;
                            bottom: 9px;
                            right: 14px;
                            z-index: 2;

                            @media screen and (min-width: 2000px) {
                                bottom: 12px;
                                right: 19px;
                            }
            
                            img {
                                width: 30px; // Adjust the size of the info icon
                                height: auto;

                                @media screen and (min-width: 2000px) {
                                    width: 42px;
                                }
                            }
                        }        
      
                        video {
                            width: 100%;
                            height: 100%;
                            border-radius: 0.45rem;
                            box-shadow: 0 0 25px rgba(0, 0, 0, 0);
                            border: 1px solid rgba(255, 255, 255, 0.1);
                            object-fit: cover;
                            z-index: 1;
                            transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                        }
      
                        &:hover {
                            z-index: 2;          
                            border-radius: 0.45rem;
                            box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
                            transform: scale(1.325);
                            transition: all 0.3s ease-in-out;
                        }
      
                        &:not(:hover) {
                            transition: box-shadow 0.3s ease-in-out, z-index ease-in-out 0.3s, transform 0.3s ease-in-out; // Add delay to transform
                            box-shadow: 0 0 25px rgba(0, 0, 0, 0);          
                            border-radius: 0.45rem;
                            z-index: 1;
                            transform: scale(1); // You can set the default scale here
                
                            &:after {
                                content: '';
                                position: absolute;
                                width: 0;
                                height: 0;
                                border-radius: 0.45rem;
                                transition: all 0.3s ease-in-out;
                            }
                        }

                        .app__press-hover {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0);
                            border-radius: 0.45rem;
                            transition: background-color 0.3s ease-in-out;
                            z-index: 3;
                        
                            &:hover {
                              background-color: rgba(0, 0, 0, 0.1);
                            }                
                        
                            &:not(:hover) {
                              transition: background-color 0.3s ease-in-out;
                              background-color: rgba(0, 0, 0, 0);
                            }
                        }
                    }
                }
            }
        }       

        .app__press-dropdown {
            position: relative;

            .app__press-selected {
                cursor: pointer;
                display: flex;
                align-items: center; /* Align items vertically */
                padding: 0.6rem 0.75rem;
                padding-bottom: 0.25rem;
                border: 1px solid #ccc;
                background-color: rgba(0, 0, 0, 0.5);
                color: #fff;
                font-weight: 800;

                .dropdown-icon {
                    width: auto;
                    height: 100%; /* Set height to 100% to match the text height */
                    margin-left: 1rem; /* Add some margin for spacing */
                    
                    img {
                        width: 12px; /* Adjust the width of the triangle icon */
                        height: 100%;
                    }
                }
            }
        }

        .app__press-options {
            position: absolute;
            top: calc(100% + 0.05px); /* Adjust the spacing between the dropdown and options */
            width: 100%;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.93);
            backdrop-filter: blur(3px);
            border: 0.25px solid rgba(255, 255, 255, 0.2);
            overflow: hidden;
            z-index: 1000;

            div {
                padding: 0.5rem 1rem;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    text-decoration: underline;
                    color: #fff;
                }
            }
        }
    }
  
    @media screen and (max-width: 850px) {
      .app__press-gif-player {
        img {
          width: 85vw;
          max-width: none;
        }
      }
    }

    .bottom-tag {
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            font-size: 1rem;            
            margin-top: 1.25rem;
            margin-bottom: -1.5rem;
            font-weight: 400;
            color: var(--white-color);
            text-align: center; /* Add this line to center the text */

            @media screen and (max-width: 450px) {
                width: 80vw;
                font-size: 0.8rem;                
                margin-bottom: -2.5rem;
            }
        }
    }
}